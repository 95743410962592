import { useState, useEffect } from "react";
import FetchService from "../fetchService";
import ApiEndpoints from "../ApiEndpoints";
import { Company } from "../Company";

export type CompanyList = (Company & { categoryTitle: string })[];

interface useCompaniesParams {
  searchFilter?: string;
}

const useCompanies = (params: useCompaniesParams = {}) => {
  const { searchFilter } = params;

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [companies, setCompanies] = useState<CompanyList>([]);

  const fetchCompanies = async () => {
    setIsLoadingCompanies(true);
    const { companies } = await FetchService.request(
      ApiEndpoints.COMPANIES_LIST,
      {
        body: JSON.stringify({ searchFilter }),
      }
    );
    setCompanies(companies);
    setIsLoadingCompanies(false);
  };

  const fetchCompaniesWrap = () => {
    fetchCompanies();
  };

  useEffect(fetchCompaniesWrap, [searchFilter]);
  return { isLoadingCompanies, companies, fetchCompanies };
};
export default useCompanies;
