import React from "react";
import Map from "../components/Map/Map";

import "../styles/Home.scss";
import NewsList from "../components/NewsList";
import { DownOutlined } from "@ant-design/icons";
import animateScrollTo from "animated-scroll-to";
import useCompanies from "../shared/hooks/useCompanies";
import CompaniesStats from "../components/CompaniesStats";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const HomePage = () => {
  const { companies } = useCompanies();

  const location = useLocation();
  const params = queryString.parse(location.search);

  const minVersion = !!params.minVersion;

  return (
    <div className={`home ${minVersion ? "home--min-version" : ""}`}>
      {!minVersion && (
        <div className="home__sidebar home__sidebar--left">
          <CompaniesStats companies={companies} />
        </div>
      )}
      <div className="home__map">
        <div className="home__logo">
          <img src="img/lid-logo.svg" alt="La Izquierda Diario" />
        </div>
        <Map companies={companies} />
        <button
          className="home__scroll-button"
          onClick={() => {
            animateScrollTo(window.innerHeight, { speed: 300 });
          }}
        >
          <span className="home__scroll-button-title">
            MAS INFORMACIÓN <DownOutlined style={{ marginLeft: 6 }} />
          </span>
        </button>
      </div>
      {!minVersion && (
        <div className="home__sidebar home__sidebar--right">
          <NewsList />
        </div>
      )}
    </div>
  );
};
export default HomePage;
