const URLS = {
  HOME: "/",
  LOGIN: "/login",
  ADMIN: "/admin",
  ADMIN_EXPORT: "/admin/exportar",
  ADMIN_USERS: "/admin/usuarios",
  ADMIN_USERS_NEW: "/admin/usuarios/nuevo",
  ADMIN_USERS_EDIT: "/admin/usuarios/editar/:_id",
  ADMIN_COMPANIES: "/admin/empresas",
  ADMIN_COMPANIES_NEW: "/admin/empresas/nuevo",
  ADMIN_COMPANIES_EDIT: "/admin/empresas/editar/:_id",
  ADMIN_CATEGORIES: "/admin/sectores",
  ADMIN_CATEGORIES_NEW: "/admin/sectores/nuevo",
  ADMIN_CATEGORIES_EDIT: "/admin/sectores/editar/:_id",
  ADMIN_UNIONS: "/admin/sindicatos",
  ADMIN_UNIONS_NEW: "/admin/sindicatos/nuevo",
  ADMIN_UNIONS_EDIT: "/admin/sindicatos/editar/:_id",
};
export default URLS;
