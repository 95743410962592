const ApiEndpoints = {
  LOGIN: "auth/login",
  COMPANIES_LIST: "companies/list",
  COMPANIES_DETAIL: "companies/detail",
  COMPANIES_ADD: "companies/add",
  COMPANIES_EDIT: "companies/edit",
  COMPANIES_REMOVE: "companies/remove",
  CATEGORIES_LIST: "categories/list",
  CATEGORIES_DETAIL: "categories/detail",
  CATEGORIES_ADD: "categories/add",
  CATEGORIES_EDIT: "categories/edit",
  ARTICLES_GET_LATEST: "articles/getLatest",
  ARTICLES_GET_FEATURED: "articles/getFeatured",
  ARTICLES_GET_DETAILS: "articles/getDetails",
  USERS_LIST: "users/list",
  USERS_DETAIL: "users/detail",
  USERS_ADD: "users/add",
  USERS_EDIT: "users/edit",
  UNIONS_LIST: "unions/list",
  UNIONS_DETAIL: "unions/detail",
  UNIONS_ADD: "unions/add",
  UNIONS_EDIT: "unions/edit",
  EXPORT_CASES: "export/cases",
  EXPORT_DEATHS: "export/deaths",
};
export default ApiEndpoints;
