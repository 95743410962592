import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Spin,
  Select,
  DatePicker,
  InputNumber,
  Space,
  Checkbox,
  Tooltip,
} from "antd";
import { get } from "lodash";
import BackCardTitle from "../../BackCardTitle";
import FetchService from "../../../shared/fetchService";
import ApiEndpoints from "../../../shared/ApiEndpoints";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { Company } from "../../../shared/Company";
import URLS from "../../../shared/urls";
import useCategories from "../../../shared/hooks/useCategories";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import PlaceSelector from "../../PlaceSelector";
import useUnions from "../../../shared/hooks/useUnions";
import { GENDERS_ENUM, GENDERS_LABELS } from "../../../shared/genderEnum";
import { DATE_FORMAT } from "../../../shared/config";
import { Worker } from "../../../shared/Worker";

interface CompanyFormValues {
  date: moment.Moment;
  title: string;
  categoryId: string;
  unionId: string;
  location: {
    description: string;
    place_id: string;
    coords: {
      lat: number;
      lng: number;
    };
  };
  imageUrl: string;
  source: string;
  covidCases: number;
  deaths: (Worker | {})[];
}

interface AdminCompanyDetailRouteParams {
  _id?: string;
}

const AdminCompaniesDetail: React.FunctionComponent<RouteComponentProps<
  AdminCompanyDetailRouteParams
>> = ({ match, history }) => {
  const location = useLocation();
  const [locationStateDefaultValues] = useState(
    get(location, "state.defaultValues", undefined)
  );

  const isEditing = !!match.params && match.params._id;
  const companyId = isEditing && match.params._id;

  const { categories, isLoadingCategories } = useCategories();
  const { unions, isLoadingUnions } = useUnions();

  const [form] = Form.useForm();

  const [isSending, setIsSending] = useState(false);
  const saveForm = async (values: any) => {
    setIsSending(true);

    try {
      const formValues: CompanyFormValues = values;
      console.log(formValues);
      formValues.deaths = formValues.deaths.filter((d) => get(d, "name"));

      // Convert selected place to coordinates
      const results = await getGeocode({
        placeId: formValues.location.place_id,
      });
      const coords = await getLatLng(results[0]);

      const { location, date } = formValues;

      const companyValues = {
        ...formValues,
        date: date.toDate(),
        location: {
          place_id: location.place_id,
          description: location.description,
          coords,
        },
      };

      const endpoint = isEditing
        ? ApiEndpoints.COMPANIES_EDIT
        : ApiEndpoints.COMPANIES_ADD;
      await FetchService.request(endpoint, {
        body: JSON.stringify({
          companyValues,
          companyId,
        }),
      });
      form.resetFields();
      message.success(isEditing ? "Empresa actualizada" : "Empresa guardada");

      if (isEditing) {
        history.push(URLS.ADMIN_COMPANIES);
      }
      if (locationStateDefaultValues) {
        history.push(URLS.ADMIN_COMPANIES_NEW);
      }
    } catch (e) {
      message.error(e);
    } finally {
      setIsSending(false);
    }
  };

  const [company, setCompany] = useState<Company>();
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      setIsLoadingCompany(true);
      const { company } = await FetchService.request(
        ApiEndpoints.COMPANIES_DETAIL,
        {
          body: JSON.stringify({ companyId }),
        }
      );
      setCompany(company);
      setIsLoadingCompany(false);
    };

    if (isEditing) {
      fetchCompany();
    }
  }, [isEditing, companyId]);

  const isLoading = isEditing && isLoadingCompany;

  if (isLoading) {
    return <Spin />;
  }

  let initialValues: Partial<CompanyFormValues> = {
    date: moment(),
    deaths: [{}],
    covidCases: 0,
  };

  if (company && isEditing) {
    initialValues = {
      title: company.title,
      source: company.source,
      categoryId: company.categoryId,
      unionId: company.unionId,
      covidCases: company.covidCases,
      date: moment(company.date),
      location: company.location,
      imageUrl: company.imageUrl,
      deaths: company.deaths.length ? company.deaths : [{}],
    };
  }

  return (
    <Card title={<BackCardTitle title="Agregar empresa" />}>
      <Row>
        <Col md={{ span: 20, offset: 2 }}>
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={saveForm}
            initialValues={initialValues}
          >
            <Form.Item label="Fecha" name="date" rules={[{ required: true }]}>
              <DatePicker autoFocus format={DATE_FORMAT} />
            </Form.Item>

            <Form.Item
              label="Empresa"
              name="title"
              rules={[{ required: true }]}
            >
              <Input autoFocus />
            </Form.Item>

            <Form.Item
              label="Sector/Rama"
              name="categoryId"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                loading={isLoadingCategories}
                showSearch
                optionFilterProp="children"
              >
                {categories.map((category) => (
                  <Select.Option key={category._id} value={category._id}>
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Sindicato" name="unionId">
              <Select
                allowClear
                loading={isLoadingUnions}
                showSearch
                optionFilterProp="children"
              >
                {unions.map((union) => (
                  <Select.Option value={union._id}>{union.title}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            {!company?.location && company?.migrationData && (
              <p>
                dire: {company.migrationData.address},{" "}
                {company.migrationData.city}
                <br />
                cp: {company.migrationData.cp}
              </p>
            )}

            <Form.Item
              label="Lugar/Dirección"
              name="location"
              rules={[{ required: true }]}
            >
              <PlaceSelector />
            </Form.Item>

            <Form.Item label="Url imagen" name="imageUrl">
              <Input />
            </Form.Item>

            <Form.Item
              label="Fuente"
              name="source"
              style={{ marginBottom: 10 }}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Casos COVID"
              name="covidCases"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.List name="deaths">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={`Fallecido ${index + 1}`}
                        required={false}
                        key={field.key}
                        style={{ marginBottom: 10 }}
                      >
                        <Space
                          style={{ display: "flex", marginBottom: 8 }}
                          align="start"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            validateTrigger={["onChange", "onBlur"]}
                            noStyle
                          >
                            <Input placeholder="Nombre" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "age"]}
                            fieldKey={[field.fieldKey, "age"]}
                            validateTrigger={["onChange", "onBlur"]}
                            noStyle
                          >
                            <InputNumber placeholder="Edad" min={0} />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "gender"]}
                            fieldKey={[field.fieldKey, "gender"]}
                            validateTrigger={["onChange", "onBlur"]}
                            noStyle
                          >
                            <Select
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              style={{ width: 150 }}
                              placeholder="Género"
                            >
                              {Object.values(GENDERS_ENUM).map((genderCode) => (
                                <Select.Option
                                  value={genderCode}
                                  key={genderCode}
                                >
                                  {GENDERS_LABELS[genderCode]}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "source"]}
                            fieldKey={[field.fieldKey, "source"]}
                            validateTrigger={["onChange", "onBlur"]}
                            noStyle
                          >
                            <Input placeholder="Fuente" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "covidAtRisk"]}
                            fieldKey={[field.fieldKey, "covidAtRisk"]}
                            validateTrigger={["onChange", "onBlur"]}
                            label="Grupo riesgo?"
                            valuePropName="checked"
                          >
                            <Checkbox />
                          </Form.Item>

                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item wrapperCol={{ md: { offset: 4 } }}>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                      >
                        <PlusOutlined /> Agregar fallecido
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            {/* <Form.List name="links">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={`Link LID ${index + 1}`}
                        required={false}
                        key={field.key}
                        style={{ marginBottom: 10 }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          noStyle
                        >
                          <Input
                            type="url"
                            placeholder="ej: https://www.laizquierdadiario.com/Rescates-para-los-ricos-virus-para-el-resto-de-nosotros"
                            style={{ marginRight: 8 }}
                          />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : (
                          <></> // IDK
                        )}
                      </Form.Item>
                    ))}
                    <Form.Item wrapperCol={{ md: { offset: 4 } }}>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        disabled={fields.length >= 3}
                      >
                        <PlusOutlined /> Agregar más links LID
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List> */}

            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSending}
                loading={isSending}
              >
                Guardar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
export default withRouter(AdminCompaniesDetail);
