import React from "react";
import { Company } from "../../shared/Company";
import { Popup } from "react-map-gl";
import "../../styles/MapPopup.scss";

import "../../styles/ArticleMini.scss";

interface MapPopup {
  company?: Company & { deathsCount?: number };
  onClose: () => void;
}

const MapPopup: React.FunctionComponent<MapPopup> = ({ company, onClose }) => {
  if (!company) {
    return null;
  }

  return (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={company.location.coords.lng}
      latitude={company.location.coords.lat}
      closeOnClick={false}
      onClose={() => onClose()}
      className="map-popup"
      dynamicPosition={false}
    >
      <div className="map-popup__inner">
        <h3 className="map-popup__title">{company.title}</h3>
        <p>Lugar: {company.location.description}</p>

        <p>
          Casos: <strong>{company.covidCases}</strong>
        </p>
        <p>Fallecidos: {company.deathsCount}</p>

        {company.source && <p>Fuente: {company.source}</p>}
      </div>
    </Popup>
  );
};
export default MapPopup;
