import React from "react";

import "../styles/CompaniesStats.scss";
import { Skeleton } from "antd";
import { orderBy } from "lodash";
import { Category } from "../shared/Category";
import PercentageStats from "./PercentageStat";
import { CompanyList } from "../shared/hooks/useCompanies";
import useCategories from "../shared/hooks/useCategories";

interface CompaniesStatsProps {
  companies: CompanyList;
}

interface CategoryStat {
  id: string;
  title: string;
  count: number;
}

const getCategoriesStats = ({
  companies,
  categories,
}: {
  companies: CompanyList;
  categories: Category[];
}) => {
  const categoryStats: CategoryStat[] = categories.map((category) => ({
    id: category._id,
    title: category.title,
    count: 0,
  }));
  companies.forEach((company) => {
    const categoryStat = categoryStats.find((c) => c.id === company.categoryId);
    if (categoryStat) {
      categoryStat.count += company.covidCases;
    }
  });
  return orderBy(
    categoryStats.filter((c) => c.count),
    "count",
    "desc"
  );
};

const CompaniesStats: React.FunctionComponent<CompaniesStatsProps> = ({
  companies,
}) => {
  const { categories, isLoadingCategories } = useCategories();

  if (isLoadingCategories || !companies.length) {
    return (
      <div style={{ opacity: 0.6 }}>
        <Skeleton active />
      </div>
    );
  }

  const total = companies.reduce((acc, company) => acc + company.covidCases, 0);

  const categoriesStats = getCategoriesStats({ companies, categories });

  return (
    <div className="company-stats">
      <span className="company-stats__main-number">{total}</span>
      <span className="company-stats__main-label">casos</span>

      <hr className="separator" />

      <div>
        {categoriesStats.map(({ id, count, title }) => (
          <PercentageStats
            key={id}
            title={title}
            number={count}
            total={total}
          />
        ))}
      </div>
    </div>
  );
};
export default CompaniesStats;
